<template>
  <div class="rule">
    <div>
      <b class="title">1、服务说明</b>
      <p>
        <br />用户在礼轻送平台购买惊喜盲盒，应遵守本规则。用户开始使用及/或继续使用本服务，即视为用户同意并已经接受本规则中全部内容。此后用户不得以未阅读/未同意本规则内容或类似理由提出任何形式的抗辩。
      </p>
    </div>
    <div>
      <b class="title">2、惊喜盲盒</b>
    </div>
    <div>
      <p>
        2.1
        惊喜盲盒是本公司在线销售的盲盒商品，用户选择并支付一定费用购买惊喜盲盒，可随机获得一款商品。用户理解并同意、惊喜盲盒商品因商品的特殊性可能存在一定瑕疵。<br />
        2.2
        惊喜盲盒内商品价值不同，相应惊喜盲盒价格也不同。惊喜盲盒价格以用户购买时页面展示的金额为准，用户购买惊喜盲盒，根据平台提供的支付方式进行支付。<br />
        2.3
        用户可在商品详情页查看购买该惊喜盲盒商品可获得的商品信息，获得不同商品的概率将在活动页面根据相关规定进行公示。用户理解并同意，购买同一惊喜盲盒，可能获得重复同一商品。<br />
        2.4
        惊喜盲盒的商品信息，包括但不限于商品的名称、规格/型号、颜色、价格、数量、是否有货等，以商品详情页展示内容为准。用户理解并同意，商品信息可能随时发生变动，本公司将不会另行通知，具体以用户下单时商品详情页展示的信息为准。
      </p>
    </div>
    <div>
      <b class="title">3、惊喜盲盒商品提货</b>
      <p>
        <br />
        3.1
        用户购买惊喜盲盒，支付成功双方买卖合同生效。在线开盒后，用户购买的商品将放入我的盲盒，用户可在我的盲盒查看已买到的商品，并进行提货操作。<br />
        3.2
        用户在我的盲盒进行提货操作，生成发货订单，在一定时间内（具体以系统设置为准），用户可取消提货操作，商品重新回到我的盲盒。当订单显示“已发货”状态，则提货操作不可取消。<br />
        3.3
        用户提货时，同一时间发往同一收货地址的商品超过5个（含5个），可享受平台提供的中国大陆地区的包邮服务，同一时间发往同一收货地址的商品不足5个，用户需支付15元运费/次。<br />
        3.4
        用户进行提货操作并支付运费后（若有），本公司将安排发货。用户理解并同意，商品页面展示及客服通知的时间为准。<br />
        3.5 用户可在“我的盲盒-待收货-订单详情”查看已发货商品的物流信息。<br />
        3.6
        用户需要在商品放入我的盲盒15个自然日内完成提货或回收，否则视为交易违约，扣除30%开盒消耗并系统自动回收。
      </p>
    </div>
    <div>
      <b class="title">4、售后</b>
      <p>
        <br />4.1
        用户理解并同意，鉴于惊喜盲盒商品的特殊性，用户在线开盒后，不支持七天无理由退货（质量问题除外）。<br />
        4.2
        用户收到盲盒商品后，出现以下质量问题的，用户提供提交开箱视频，由本公司进行确认及受理：
        商品错发或者数量不符、商品在运输过程造成严重破损，商品做工存在明显瑕疵。<br />
        4.3
        因工艺原因存在微瑕的盲盒、手办公仔类商品，电镀板件水纹，轻微盒损的商品，商品漆味重，均不属于质量问题。
      </p>
    </div>
    <div>
      <b class="title">5、惊喜盲盒商品的回收</b>
      <p>
        <br />5.1
        若用户不喜欢购买的商品，可在我的盲盒进行回收操作。用户理解并同意，自商品进入我的盲盒之日起16天内，若用户未主动进行提货操作，则视为用户不喜欢，自商品进入我的盲盒之日起满15天后次日零点，系统将自动发起回收操作。<br />
        5.2
        回收属于用户对惊喜盲盒买卖合同的违约行为，用户同意以惊喜盲盒价格的20%向本公司支付违约金。<br />
        5.3 商品回收的，违约金和退款按以下方式处理：<br />
        5.3.1
        用户购买惊喜盲盒时，使用人民币支付的，以用户在购买惊喜盲盒时实际支付的现金金额为基数，扣除20%金额作为违约金，剩余款项原路返还至用户支付账户；<br />
        5.3.2
        用户在购买惊喜盲盒时，若使用任何形式的优惠券，商品回收时，优惠券不予计算，也不予返还，退款金额按照用户购买惊喜盲盒时实际支付的金额为基数，扣除违约金后，退还剩余金额。<br />
        5.4 用户可在“惊喜盲盒-我的盲盒-已回收”查看已回收商品的情况。
      </p>
    </div>
    <div class="bottom" style="text-align:center;color:#bbb;font-size:15px">
      <div class="bottom-layout">
        <div style="margin-bottom:10px;text-align:center;">
          以上最终解释权归广东番茄科技有限公司所有
        </div>
        <div class="bottom-container" style="text-align:center;">
          © 2021 Tomato Technology | 广东番茄科技有限公司 |
          <span>粤ICP备2021062901号-1</span>
          <!-- <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备2021062901号-1</a> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup scoped>
export default {
  data() {
    return {
      key: 1,
    };
  },
  methods: {
    
  },
};
</script>

<style lang="less" scoped>
.rule {
  padding: 20px;
  .title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  div {
    text-align: left;
    margin-bottom: 20px;
    p {
      line-height: 28px;
      font-size: 14px;
    }
  }
}
</style>
